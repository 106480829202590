import Meta from 'antd/es/card/Meta';
import './index.scss';
import { Avatar, Card } from 'antd';
import { useSelector } from 'react-redux';
import HomePage from 'assets/images/home2.png';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { HOME_DASHBOARD_UUID } from 'types/enums';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Home: React.FC<any> = () => {
    const cookies = new Cookies();
    const { t } = useTranslation('translation');

    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const allowedTabList = useSelector(
        (state: any) => state.root.allowedMenuList
    );
    const loggedInUserDashboardBuilderDetails = useSelector(
        (state: any) =>
            state.userManagement.users?.loggedInUserDashboardBuilderDetails
    );
    const [isDashboardMenu, setIsDashboardMenu] = useState<any>();

    const commonRawDashboardUrl =
        'https://dashboard-builder.{environment}test.solulever.com';
    const applicationUrl = window.location.hostname;
    const generatedSupersetDomainUrl = (): any => {
        const environment = applicationUrl.split('.')[1];
        return commonRawDashboardUrl.replace('{environment}', environment);
    };

    useEffect(() => {
        const embed = async (payload?: any): Promise<any> => {
            embedDashboard({
                id: HOME_DASHBOARD_UUID.dashboardUuid,
                supersetDomain: generatedSupersetDomainUrl(),
                mountPoint: document.getElementById(
                    'embedded-dashboard'
                ) as any,
                fetchGuestToken: (): any => cookies.get('session'),
                dashboardUiConfig: {
                    hideTitle: false,
                    hideChartControls: false,
                    hideTab: true,
                },
            });
        };
        if (document.getElementById('embedded-dashboard')) {
            embed();
        }
    }, [isDashboardMenu]);

    useEffect(() => {
        const isDiageoPlantDashboardExists =
            loggedInUserDashboardBuilderDetails?.dashboardEntries?.some(
                (item: any) => item.id === 12
            );
        setIsDashboardMenu(isDiageoPlantDashboardExists);
    }, [
        allowedTabList,
        loggedInUserDetails,
        loggedInUserDashboardBuilderDetails,
    ]);

    return (
        <>
            <div className="home">
                <Card className="home__userDetails">
                    <div className="userDetails">
                        <Meta
                            avatar={
                                <Avatar
                                    src={loggedInUserDetails.profileImage}
                                />
                            }
                            title={t('home.welcome')}
                            description={
                                <div className="details">
                                    <div className="name fw-200 fs-32">
                                        {loggedInUserDetails.firstName}
                                        {!loggedInUserDetails.admin &&
                                            loggedInUserDetails.lastName}
                                    </div>
                                    <div className="designantion fw-400 fs-16">
                                        {loggedInUserDetails.designation}
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="currentTime">
                        <div className="date currentDateTimeComponent__time">
                            {moment().format('LLLL')}
                        </div>
                        <div className="homePageImage">
                            <img src={HomePage} />
                        </div>
                    </div>
                </Card>

                <Card className="home__dashboardSection">
                    {isDashboardMenu && (
                        <>
                            <span className="fs-16 fw-600 dashboard-heading">
                                Dashboard
                            </span>
                            <div className="dashboard-screen">
                                <div id="embedded-dashboard" />
                                {/* use this code later */}
                                {/* <img src={HomePage} />
                        <text>
                            You Have Not Made Any Dasboard Favourite Yet
                        </text>
                        <div>
                            <CustomButton
                                type={'viewAll'}
                                disabled={false}
                                handleClick={() => {}}
                            />
                        </div> */}
                            </div>
                        </>
                    )}
                </Card>
            </div>
        </>
    );
};

export default Home;
