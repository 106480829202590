import { useState } from 'react';
import { type SidebarProps } from 'types/interfaces/PropsInterfaces';
import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg';
import { ReactComponent as CollapseIcon } from 'assets/icons/collapse.svg';
import { Input, Menu } from 'antd';
import './index.scss';
import { SearchOutlined } from '@ant-design/icons';
import BraboLogo from 'assets/icons/braboLogo.svg';
import { ROLETYPE, UseCasesMenu } from 'types/enums';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAddBlaState,
    setBlaId,
    setDeviceId,
    setSidebarNavigate,
} from 'redux/actions/DeviceManagementActions/blasAction';
import { setAddDeviceState } from 'redux/actions/DeviceManagementActions/deviceAction';
import { baseUrlSetter } from 'utils/commonFunction';
import { setConnectionsState } from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import { ConnectionsState } from 'types/enums/businessIntegratorEnum';
import { useTranslation } from 'react-i18next';

const Sidebar: React.FC<SidebarProps> = ({
    collapsed,
    menu,
    changeSideBar,
    setSearchValue,
    searchValue,
}) => {
    const { t } = useTranslation('translation');

    const dispatch = useDispatch();

    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const reportList = useSelector(
        (state: any) => state?.dataExplorer?.dashboardReporting?.reportingList
    );

    const toggleCollapse = (): any => {
        changeSideBar(!collapsed);
    };

    const [selectedKeys, setSelectedKeys] = useState<any>(() => {
        const storedKeyPath = localStorage.getItem('keyPath');
        if (storedKeyPath) {
            try {
                const keyPathArray = JSON.parse(storedKeyPath);
                return keyPathArray;
            } catch (error) {
                return undefined;
            }
        } else {
            return undefined;
        }
    });

    const redirectionUrlHandler = ({ keyPath, e }: any): any => {
        localStorage.setItem(
            'keyPath',
            JSON.stringify([keyPath[0], keyPath[keyPath.length - 1]])
        );
        setSelectedKeys(keyPath);
        dispatch(setSidebarNavigate(true));
        dispatch(setAddBlaState(ROLETYPE.view));
        dispatch(setConnectionsState(ConnectionsState.tableView));
        dispatch(setBlaId(null));
        dispatch(setDeviceId(null));
        dispatch(setAddDeviceState(false));
        // const commonRawServiceUrl =
        //     '{service-name}.{environment}.solulever.com/';
        // const applicationUrl = window.location.hostname;

        // if (e?.key === UseCasesMenu.AssetModeller) {
        //     const splittedUrlArray = applicationUrl.split('.');
        //     const generatedUrl = commonRawServiceUrl
        //         .replace('{service-name}', 'brabo-config')
        //         .replace('{environment}', splittedUrlArray[1]);
        //     window.open('https://' + generatedUrl + 'dataModel', '_blank');
        // }
        if (keyPath.includes(UseCasesMenu.BoilerMonitoring)) {
            window.open('https://brabo-platform.nocil.solulever.com/login');
        }
        if (keyPath.includes(UseCasesMenu.CPGMonitoring)) {
            window.open('https://mesukprd.upl-ltd.com/');
        }
        if (keyPath.includes(UseCasesMenu.DashboardBuilder)) {
            const url =
                baseUrlSetter('reportConfigurator') + '/superset/welcome/';
            window.open(url, '_blank');
        }
        if (reportList?.length) {
            reportList?.map((item: any) => {
                if (keyPath.includes(item?.menuName)) {
                    window.open(item?.externalUrl, '_blank');
                }
            });
        }

        // Note: breakign chnage , working on it
        // else {
        //     const splittedUrlArray = applicationUrl.split('.');
        //     const generatedUrl = commonRawServiceUrl
        //         .replace('{service-name}', splittedUrlArray[0])
        //         .replace('{environment}', splittedUrlArray[1]);
        //     window.open(generatedUrl, '_blank');
        // }
    };

    // Specify the mount node for the dropdown
    // You can return the desired container element here
    const getPopupContainer = (node: HTMLElement): HTMLElement => {
        const theme = localStorage.getItem('default-theme');
        if (theme === 'dark') {
            return (
                (document.querySelector('.theme-dark') as HTMLElement) ||
                document.body
            );
        } else {
            return (
                (document.querySelector('.theme-light') as HTMLElement) ||
                document.body
            );
        }
    };
    // Note: Recursive function to translate menu items
    const translateMenuItems = (menuItems: any[]): any[] => {
        return menuItems?.map((item) => {
            if (item.key === 'Dashboards') {
                return {
                    ...item,
                };
            }
            const translatedItem = {
                ...item,
                label: {
                    ...item?.label,
                    props: {
                        ...item.label?.props,
                        children: t(
                            `menu.${
                                item?.key && typeof item.key === 'string'
                                    ? item?.key
                                          ?.toLowerCase()
                                          ?.replace(/\s+/g, '')
                                    : ''
                            }`
                        ),
                    },
                },
            };

            if (item?.children) {
                translatedItem.children = translateMenuItems(item?.children);
            }

            return translatedItem;
        });
    };

    const translatedMenu = translateMenuItems(menu);

    return (
        <>
            <div className="switch-module-icon">
                <img src={BraboLogo} alt="light-icon" />
            </div>
            <div className="filter-navigator">
                {!collapsed ? (
                    <>
                        <Input
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                            className="filter-search"
                            prefix={<SearchOutlined />}
                            placeholder={t('calenderConfig.Search')}
                            disabled={!loggedInUserDetails.admin}
                        />
                    </>
                ) : (
                    ''
                )}
                {collapsed ? (
                    <ExpandIcon onClick={toggleCollapse} />
                ) : (
                    <CollapseIcon onClick={toggleCollapse} />
                )}
            </div>

            <div
                className={
                    collapsed
                        ? 'sider-container-collapsed'
                        : 'side-menu-tabs-expanded'
                }
            >
                <Menu
                    onClick={redirectionUrlHandler}
                    getPopupContainer={getPopupContainer}
                    mode="vertical"
                    items={translatedMenu}
                    selectedKeys={selectedKeys}
                />
            </div>
        </>
    );
};

export default Sidebar;
